// authReducer.js
const initialState = {
  authToken: localStorage.getItem('authToken') || null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AUTH_TOKEN': {
      localStorage.setItem('authToken', action.payload);
      return {...state, authToken: action.payload};
    }
    case 'LOGOUT':
      localStorage.removeItem('authToken');
      return {
        ...state,
        authToken: null,
        // Clear any other auth-related state here
      };
    default:
      return state;
  }
};

export default authReducer;
