import React from "react";
import Button from "../Button/Button";
import DragDropFiles from "../DragImage/DragDropFiles";
import Drag from "../DragImage/Drag";
import {setProductDetails} from "../../../redux/productActions";
import {useDispatch, useSelector} from "react-redux";

const Media = ({slabDetail, preStep}) => {

  const product = useSelector((state) => state.product.product)

  const dispatch = useDispatch();

  const stoneImage = (name, img) => {
    const imgArr = img.map((im) => im?.split(',')[1]);
    if (name === 'application_images') {
      dispatch(setProductDetails({...product, [name]: img.length === 0 ? [] : [...product[name], ...imgArr]}));

    } else {
      dispatch(setProductDetails({...product, [name]: img.length === 0 ? [] : [...imgArr]}));

    }
  }

  return (
    <div className="card_container">
      <div className="stone"> Product Details</div>
      <div className="steps_container">
        <div className="step step_1">
          <span>1</span>
          <p>Details</p>
        </div>
        <div className="line"></div>
        <div className="step step_2">
          <span>2</span>
          <p>Info</p>
        </div>
        <div className="line"></div>
        <div className="step step_3 active">
          <span>3</span>
          <p>Media</p>
        </div>
      </div>

      <div className="media_container">
        <div className="media_drag">
          <DragDropFiles multiple={true} name="Application Image" base64Image={product.application_images} stoneImage={(image) => stoneImage('application_images', image)} />
        </div>

        <div className="media_drag">
          <DragDropFiles multiple={false} name="Slab Image" base64Image={product.slab_images} stoneImage={(image) => stoneImage('slab_images', image)} />
        </div>

        <div className="media_drag">
          <DragDropFiles multiple={false} name="Visualiser Image" base64Image={product.visualiser_images} stoneImage={(image) => stoneImage('visualiser_images', image)} />
        </div>
      </div>
      <div className="featured">
        <div><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 15.013 17">
          <path id="icon" d="M1173.051,5484.971a3.268,3.268,0,0,1-1.513-.623,1.314,1.314,0,0,1-.618-1c0-.425.408-.625.785-.708a23.81,23.81,0,0,1,3.57,0c.377.085.784.28.784.708a1.312,1.312,0,0,1-.613,1,3.271,3.271,0,0,1-1.511.622,3.581,3.581,0,0,1-.446.028A3.289,3.289,0,0,1,1173.051,5484.971Zm-3.723-3.587a4.061,4.061,0,0,1-2.559-1.2,3,3,0,0,1-.762-2.021,2.793,2.793,0,0,1,.645-1.964,3.162,3.162,0,0,0,.938-2.419v-.365a4.669,4.669,0,0,1,1.132-3.279,6.339,6.339,0,0,1,4.747-2.135h.079a6.306,6.306,0,0,1,4.824,2.23,4.6,4.6,0,0,1,1.059,3.184v.365a3.237,3.237,0,0,0,.937,2.422,2.789,2.789,0,0,1,.64,1.961,3,3,0,0,1-.762,2.022,4.067,4.067,0,0,1-2.559,1.2c-1.386.113-2.774.21-4.18.21A38.284,38.284,0,0,1,1169.328,5481.384Z" transform="translate(-1166 -5468)" fill="#3e3dc6" />
        </svg>
          &nbsp;&nbsp;Make featured product</div>
        <label class="switch">
          {product.is_featured}
          <input type="checkbox" checked={product.is_featured === true ? true : false} onChange={(e) => {dispatch(setProductDetails({...product, is_featured: e.target.checked ? true : 'no'}))}} />
          <span class="slider round"></span>
        </label>
      </div>

      <div className="card_footer">
        <p onClick={(e) => {e.preventDefault(); preStep()}}><svg xmlns="http://www.w3.org/2000/svg" width="5.96" height="11.015" viewBox="0 0 5.96 10.015">
          <path id="arrow_dow_copy" data-name="arrow dow copy" d="M1171.727,5580.225a.82.82,0,0,1,.081,1.08l-.081.092-3.685,3.593,3.685,3.593a.82.82,0,0,1,.081,1.08l-.081.092a.849.849,0,0,1-1.094.081l-.094-.081-4.279-4.179a.82.82,0,0,1-.081-1.079l.081-.092,4.279-4.179A.849.849,0,0,1,1171.727,5580.225Z" transform="translate(-1166.013 -5579.982)" fill="#7a86a1" />
        </svg>

          &nbsp;&nbsp;Previous step</p>
      </div>

    </div>
  );
};

export default Media;
