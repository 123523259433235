import React, {useState} from "react";
import ReactDOM from "react-dom";

import './modal.scss';

const Modal = ({isOpen, title, description, subDescription, onClose, onCancel, onAction, actionBtn, type, isLoading}) => {
  if (!isOpen) return null;
  const className = "modal-overlay " + `${type === 'red' ? 'red' : ''}`
  return ReactDOM.createPortal(
    <div className={className}>

      {
        isLoading ? <div className="loader-img"><img src="../../kiva-white-dark.gif" width={100} /></div> :
          <div className="modal-content">
            <div className="modal-header">
              <h2>{title}</h2>
              <button className="close-button-modal" onClick={() => {onClose()}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 12.728 12.728">
                  <path id="icon_close" data-name="icon close" d="M6.364,7.779l-4.95,4.949L0,11.314l4.949-4.95L0,1.415,1.414,0l4.95,4.95L11.313,0l1.415,1.415L7.778,6.364l4.95,4.95-1.415,1.414Z" fill="#7a86a1" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <p className="description">{description}</p>
              <p>{subDescription}</p>
            </div>
            <div className="modal-footer">
              <button className="btn cancel-button" onClick={() => {onClose()}}>
                Cancel
              </button>
              <button className="btn primary action-button" onClick={onAction}>
                {actionBtn}
              </button>
            </div>
          </div>
      }


    </div>,
    document.getElementById("modal-root")
  );
};

export default Modal;
