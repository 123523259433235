// authReducer.js
export const initialState = {
  product: {
    "space_images": [],
    "visualiser_images": [],
    "slab_images": [],
    is_featured: false,
    application_images: [],
    slab_horizontal_images: [],
    thickness: [],
    finishes: []
  }
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PRODUCT_DETAILS': {
      return {...state, product: action.product};
    }
    default:
      return state;
  }
};

export default productReducer;
