import React, {useState} from 'react';

import './cateDropdown.scss';

function TagInput({tagOptions}) {
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleTagSelection = (tag) => {
    setSelectedTag('');
    setSelectedTags((prevSelected) => {
      if (!prevSelected.includes(tag)) {
        return [...prevSelected, tag];
      }
      return prevSelected;
    });
    toggleDropdown();
  };

  const removeTag = (tag) => {
    setSelectedTags((prevSelected) => prevSelected.filter((t) => t !== tag));
  };

  const availableTags = tagOptions.filter((tag) => !selectedTags.includes(tag));

  return (
    <div className='wrapper-tag'>
      <div className="tag-dropdown">
        <input
          type="text"
          placeholder="Add tags"
          value={selectedTag}
          onChange={() => { }}
          onClick={toggleDropdown}
        />

      </div>
      {isDropdownOpen && (
        <ul className="dropdown-list">
          {availableTags.map((tag) => (
            <li key={tag.code} onClick={() => handleTagSelection(tag)}>
              {tag.name}
            </li>
          ))}
        </ul>
      )}
      <div className="selected-tags">
        {selectedTags.map((tag) => (
          <span key={tag.code} className="selected-tag">
            {tag.name}{' '}
            <span onClick={() => removeTag(tag)}>&times;</span>
          </span>
        ))}
      </div>
    </div>
  );
}

export default TagInput;
