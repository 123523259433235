import React, {useEffect, useState} from "react";
import Button from "../Button/Button";
import "../form.scss";
import {useDispatch, useSelector} from "react-redux";
import CategoryInput from "../cateDropdown";
import {setProductDetails} from "../../../redux/productActions";
import Modal from "../../ModalComponent";
import {useNavigate} from "react-router-dom";
import {logout} from "../../../redux/authActions";

const Details = ({slabDetail, nextStep}) => {
  console.log(slabDetail, ' slabDetail')
  const [categories, setCategories] = useState([]);
  const [category_code, setCategory] = useState(
    slabDetail ? slabDetail.category_code : ""
  );
  const [description, setDescription] = useState(
    slabDetail ? slabDetail.description : ""
  );
  const authToken = useSelector((state) => state.auth.authToken);
  const product = useSelector((state) => state.product.product);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState(slabDetail ? slabDetail.title : "");
  const [showLoader, setLoader] = useState(null);

  useEffect(() => {
    setLoader(true);
    fetch("https://api.kivastone.com/inventory/category/v1", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authToken,
      },
    })
      .then((res) => {
        if (res.status === 401) {
          dispatch(logout());
          localStorage.removeItem("authToken"); // Clear token from localStorage
          navigate("/login");
          throw new Error;
          // You can also clear localStorage or any other necessary tasks here
        };
        return res.json()
      })
      .then((result) => {
        // store the posts in the reactive state
        const cateogry = [...result.categories];
        setCategories(cateogry);
        console.log(cateogry);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      })
  }, []);

  useEffect(() => {
    if (slabDetail && !product.category_code) {
      setTitle(slabDetail?.title);
      setDescription(slabDetail?.description);
      setCategory(slabDetail?.category_code);
      console.log('slabhere1')
      dispatch(setProductDetails({...product, is_featured: slabDetail?.is_featured, id: slabDetail?.id, title: slabDetail?.title, description: slabDetail?.description, category_code: slabDetail?.category_code}));
    }
  }, [slabDetail])

  useEffect(() => {
    setTitle(product?.title);
    setDescription(product?.description);
    setCategory(product?.category_code);
    console.log(product, 'slabhere')

  }, [product])

  const saveStepOneHandler = (e) => {
    e.preventDefault();
    console.log(title, product.title, 'title')
    const stepOneObj = {
      title: title,
      description: description,
      category: category_code,
    };

    console.log(product, 'setProductDetails')

    // dispatch(setProductDetails({...product, ...stepOneObj}));

    nextStep();
    const currentStep = 2;
  };
  console.log(category_code, 'category_code')
  return (
    <div className="card_container">
      <div className="stone"> Product Details</div>
      <div className="steps_container">
        <div className="step step_1 active">
          <span>1</span>
          <p>Details</p>
        </div>
        <div className="line"></div>
        <div className="step step_2">
          <span>2</span>
          <p>Info</p>
        </div>
        <div className="line"></div>
        <div className="step step_3">
          <span>3</span>
          <p>Media</p>
        </div>
      </div>
      <form onSubmit={saveStepOneHandler}>
        <div className="image-categ_wrap">
          <div className="image-section"></div>

          <div className="category-section">
            <div className="slab_image"></div>
            <div className="form_controler">
              <label>Category</label>
              <select
                type="text"
                value={category_code}
                onChange={(e) => {
                  let val = e.target.value;
                  dispatch(setProductDetails({...product, category: val, category_code: val}))
                }}
              >
                <option>-Select Category-</option>
                {categories.map((ctg) => (
                  <option value={ctg.code}>{ctg.name}</option>
                ))}
              </select>
              {/* <CategoryInput tagOptions={categories} /> */}
            </div>
          </div>
        </div>

        <div className="form_controler">
          <label>Stone Name</label>
          <input disabled={showLoader}
            type="text"
            placeholder="Enter Stone Name"
            value={product.title}
            onChange={(e) => dispatch(setProductDetails({...product, title: e.target.value}))}
          />
        </div>

        <div className="form_controler description-section">
          <label>Product Description</label>
          <input disabled={showLoader}
            type="text"
            placeholder="Enter product description here (200 words)"
            value={product.description}
            onChange={(e) => dispatch(setProductDetails({...product, description: e.target.value}))}
          />
        </div>
        <div className="btn_grp">
          {!showLoader && <button className="primary btn">Next</button>}
        </div>
      </form>
    </div>
  );
};

export default Details;
