import React, {useState} from "react";
import ReactDOM from "react-dom";

import './toast.scss';

const Toast = ({isOpen, title, onClose, type}) => {
  if (!isOpen) return null;
  const className = "toast-overlay " + `${type === 'red' ? 'red' : ''}`
  return ReactDOM.createPortal(
    <div className={className}>
      <div className="toast-content">
        <div className="toast-header">
          <div>{title}</div>
          <button className="close-button" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 4.018 4.018">
              <path id="icon_close" data-name="icon close" d="M2.009,2.456.446,4.018,0,3.572,1.563,2.009,0,.447.446,0,2.009,1.563,3.572,0l.447.447L2.456,2.009,4.018,3.572l-.447.446Z" fill="#fff" />
            </svg>

          </button>
        </div>
      </div>

    </div>,
    document.getElementById("toast-root")
  );
};

export default Toast;
