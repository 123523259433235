import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthToken } from "../redux/authActions";
import { useNavigate } from "react-router-dom";

import "./login.scss"; // Import the styles

const LoginPage = ({ onLogin }) => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.auth.authToken);

  const history = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    // Perform your authentication logic here
    // For simplicity, let's assume successful login for any non-empty username/password
    if (username && password) {
      fetch("https://api.kivastone.com/auth/login/token/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      })
        .then((res) => res.json())
        .then((data) => {
          // store the posts in the reactive state

          // Save token to local storage and dispatch action
          dispatch(setAuthToken(data.records.token));
          history("dashboard");
        })
        .catch((err) => alert("Invalid credential"));
    }
    return false;
  };

  useEffect(() => {
    // If user is authenticated, redirect to dashboard
    if (authToken) {
      history("/dashboard");
    }
  }, [authToken, history]);

  return (
    <div className="login-container">
      <div className="left-section">
        <div className="overlay"></div>
        <div className="logo">
          <img src="../../kiva-white.png" width={120} />
          <div className="welcome">Welcome to</div>
          <div className="panel">Kiva Stones Admin Panel</div>
          <div className="desc">
            Kindly sign in to access all inventory
            <br />
            listings and make changes
          </div>
        </div>
      </div>
      <div className="right-section">
        <form className="login-form">
          <div className="input-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className="submit-btn" onClick={handleLogin}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
