import React, {useEffect, useState} from "react";
import DragDropFiles from "./formComponents/DragImage/DragDropFiles";
import "./product.scss";
import Step from "./formComponents/ProductInfo/Step";
import Button from "./formComponents/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {Link, Router, useNavigate, useParams} from "react-router-dom";
import {logout} from "../redux/authActions";
import {setProductDetails} from "../redux/productActions";
import Modal from "./ModalComponent";
import {initialState} from "../redux/productReducer";
import SlabDetails from "./SlabDetails";
import Toast from "./toast";

const ProductDetail = () => {
  const authToken = useSelector((state) => state.auth.authToken);
  const queryParameters = new useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [image, setImage] = useState([]);
  const [modalData, setModalData] = useState({isOpen: false})
  const product = useSelector((state) => state.product.product);
  const [slabData, setSlab] = useState(null)
  const [isLoading, setLoader] = useState(false)
  const [toastData, setToastData] = useState({isOpen: false});

  console.log(product.is_featured, 'dsads')
  const finalSubmitHandler = (e) => {
    setLoader(true);

    e.preventDefault();
    console.log(product, "dsads");
    const finalObj = {
      ...product,
      category: product.category_code || '',
      space_images: product.space_images || [],
      slab_images: product.slab_images || [],
      visualiser_images: product.visualiser_images || [],
      is_featured: product.is_featured,
      application_images: product.application_images || [],
      slab_horizontal_images: product.slab_horizontal_images || [],
      finishes: product.finishes || [],
      thickness: product.thickness || []
    };

    const stones = [{...finalObj}]

    fetch("https://api.kivastone.com/inventory/stone/v1/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authToken,
      },
      body: JSON.stringify({stones}),
    })
      .then((res) => {
        if (res.status === 401) {
          dispatch(logout());
          localStorage.removeItem("authToken"); // Clear token from localStorage
          navigate("/login");
          throw new Error;
          // You can also clear localStorage or any other necessary tasks here
        } else if (res.status !== 200 && res.status !== 201) {
          throw new Error;
        }
        res.json()
      })
      .then((data) => {
        navigate('/dashboard')
        setModalData({isOpen: false})
        console.log("Product Add SuccessFully!");
      })
      .catch((err) => {
        console.log('test2')
        setModalData({isOpen: false})
        setToastData({isOpen: true, type: 'red', title: 'Error while adding product', onClose: () => setToastData({isOpen: false})});
        setTimeout(() => {
          setToastData({isOpen: false, title: ''});
        }, 4000)
      })
      .finally(() => {
        setModalData({isOpen: false})
        setLoader(false)
      })
  };

  const stoneImage = (name, img) => {
    const imgArr = img.map((im) => im?.split(',')[1]);
    dispatch(setProductDetails({...product, [name]: img.length === 0 ? [] : [...imgArr]}));
  }

  const updatePrductHandler = (e) => {
    e.preventDefault();
    console.log("dsads 2");
    setLoader(true);
    // console.log(stepTwoData, 'stepTwoData');
    const finalObj = {
      "title": product.title || '',
      item_code: product.item_code || '',
      "description": product.description || '',
      "erp_link": product.erp_link || '',
      "primary_color": "",
      "secondary_color": "",
      "category": product.category_code || '',
      "sub_category_code": "",
      "finish_type": "",
      "avg_height": "",
      "item_code": product.item_code || '',
      space_images: product.space_images || [],
      slab_images: product.slab_images || [],
      visualiser_images: product.visualiser_images || [],
      slab_horizontal_images: product.slab_horizontal_images || [],
      is_featured: product.is_featured,
      application_images: product.application_images || [],
      finishes: product.finishes || [],
      thickness: product.thickness || []

    };
    const data = {
      id: product?.id,
      updates: {...finalObj}
    }

    fetch("https://api.kivastone.com/inventory/stone/v1/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authToken,
      },
      body: JSON.stringify({...data}),
    })
      .then((res) => {
        if (res.status === 401) {
          dispatch(logout());
          localStorage.removeItem("authToken"); // Clear token from localStorage
          navigate("/login");
          throw new Error;
          // You can also clear localStorage or any other necessary tasks here
        } else if (res.status !== 200 && res.status !== 201) {
          throw new Error;
        }
        res.json()
      })
      .then((data) => {
        console.log(data);
        console.log("Product Add SuccessFully!");
        setModalData({isOpen: false});
        navigate("/login");
      })
      .catch((err) => {
        setModalData({isOpen: false})
        setToastData({isOpen: true, type: 'red', title: 'Error while updating product', onClose: () => setToastData({isOpen: false})});
        setTimeout(() => {
          setToastData({isOpen: false, title: ''});
        }, 4000)
      })
      .finally(() => {
        setModalData({isOpen: false})
        setLoader(false)
      });
  };

  useEffect(() => {
    return (() => {dispatch(setProductDetails({...initialState.product}))})
  }, [])

  useEffect(() => {
    if (slabData) {
      let space_images = [];
      let slab_images = [];
      let visualiser_images = [];
      let application_images = [];
      let slab_horizontal_images = [];

      // Create an array of promises for each image type
      const spacePromises = slabData.space_images.map((image) => getImageDataUrl(image));
      const horizontalPromises = slabData.slab_horizontal_images.map((image) => getImageDataUrl(image));
      const slabPromises = slabData.slab_images.map((image) => getImageDataUrl(image));
      const visualiserPromises = slabData.visualiser_images.map((image) => getImageDataUrl(image));
      const applicationPromises = slabData.application_images.map((image) => getImageDataUrl(image));

      // Wait for all promises to resolve
      Promise.all([
        ...spacePromises,
        ...horizontalPromises,
        ...slabPromises,
        ...visualiserPromises,
        ...applicationPromises,
      ]).then((results) => {
        // Split the results into respective arrays
        space_images = results.slice(0, slabData.space_images.length);
        slab_horizontal_images = results.slice(slabData.space_images.length, slabData.space_images.length + slabData.slab_horizontal_images.length);
        slab_images = results.slice(slabData.space_images.length + slabData.slab_horizontal_images.length, slabData.space_images.length + slabData.slab_horizontal_images.length + slabData.slab_images.length);
        visualiser_images = results.slice(slabData.space_images.length + slabData.slab_horizontal_images.length + slabData.slab_images.length, slabData.space_images.length + slabData.slab_horizontal_images.length + slabData.slab_images.length + slabData.visualiser_images.length);
        application_images = results.slice(slabData.space_images.length + slabData.slab_horizontal_images.length + slabData.slab_images.length + slabData.visualiser_images.length);

        // Update the state
        dispatch(
          setProductDetails({
            ...product,
            id: slabData.id,
            space_images,
            slab_images,
            visualiser_images,
            application_images,
            slab_horizontal_images,
          })
        );
      });
    }
  }, [slabData]);

  function getImageDataUrl(src) {
    return new Promise((resolve) => {
      var image = new Image();
      image.crossOrigin = 'Anonymous';
      image.onload = function () {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        context.drawImage(this, 0, 0);
        let dataUrl = canvas.toDataURL('image/jpeg');
        dataUrl = dataUrl.split(',')[1];
        resolve(dataUrl);
      };
      image.src = src;
    });
  }


  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("authToken"); // Clear token from localStorage
    navigate("/login");
    // You can also clear localStorage or any other necessary tasks here
  };

  console.log(product.space_images, 'product')

  return (
    <div className="container">
      <nav className="navbar">
        <div className="navbar-brand">
          <Link to='/'>
            <img src="../../logo-black.png" width={80} />
          </Link>
        </div>
        <ul className="navbar-nav">
          <li className="nav-item">
            <div onClick={handleLogout}>Logout</div>
          </li>
        </ul>
      </nav>
      <div className="head-detail">
        {
          Object.keys(queryParameters).length === 0 ? 'New Listing' : <span>Now Editing - <span className="edit-text">{queryParameters.name}</span></span>
        }
        <div className="close-editor" onClick={() => {navigate('/dashboard')}}>Close Editor
          <div className="circle"><svg xmlns="http://www.w3.org/2000/svg" width="12.728" height="12.728" viewBox="0 0 12.728 12.728">
            <path id="icon_close" data-name="icon close" d="M1297,2493.413l-4.95,4.95-1.414-1.414,4.95-4.95-4.95-4.95,1.414-1.414,4.95,4.95,4.949-4.95,1.415,1.414-4.95,4.95,4.95,4.95-1.415,1.414Z" transform="translate(-1290.636 -2485.635)" fill="#fff" />
          </svg></div>
        </div>
      </div>
      <div className="productDetail_container">

        <Modal {...modalData} isLoading={isLoading} />
        <Toast {...toastData} />

        <div className="leftmost-container">
          <DragDropFiles multiple={false} base64Image={product.space_images} name="Space Image" stoneImage={(image) => stoneImage('space_images', image)} />
          <DragDropFiles multiple={false} base64Image={product.slab_horizontal_images} name="Slab Horizontal Image" stoneImage={(image) => stoneImage('slab_horizontal_images', image)} />
        </div>
        <Step getSlab={(data) => {setSlab(data)}} />
        <div className="final_btn">
          {/* <button className="secondary btn">Preview Product</button> */}
          <button
            className="primary btn"
            onClick={(e) => {
              Object.keys(queryParameters).length === 0 ? setModalData({
                isOpen: true,
                title: 'Reconfirm Push Live',
                subDescription: 'Are you sure you wish to push this product live?',
                actionBtn: isLoading ? 'Going Live...' : 'Go Live',
                onAction: () => finalSubmitHandler(e),
                onClose: () => setModalData({isOpen: false}),
              }) : setModalData({
                isOpen: true,
                title: 'Reconfirm Update',
                description: 'Are you sure you wish to apply these changes to this listing?',
                subDescription: '',
                actionBtn: isLoading ? 'Updating...' : 'Update',
                onAction: () => updatePrductHandler(e),
                onClose: () => setModalData({isOpen: false}),
              })
            }
            }
          >
            Push Live
          </button>
        </div>
      </div >
    </div >
  );
};

export default ProductDetail;
