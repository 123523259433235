import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setProductDetails} from "../../../redux/productActions";
import Button from "../Button/Button";

const thicknessArray = ['2 cm', '3 cm', '4 cm'];
const finishesArray = ['Polished', 'Matte', 'Glossy', 'Leather', 'Dual'];

const Info = ({slabDetail, nextStep, preStep}) => {
  const [stone_finshed, setStoneFnish] = useState(
    slabDetail ? slabDetail.stone_finishes : []
  );

  const [erp_link, setInventoryLink] = useState(
    slabDetail ? slabDetail.erp_link : ""
  );

  const [item_code, setItemCode] = useState(
    slabDetail ? slabDetail.item_code : ""
  );

  const dispatch = useDispatch();
  const product = useSelector((state) => state.product.product);

  useEffect(() => {
    if (slabDetail) {
      setStoneFnish(slabDetail.stone_finishes);
      setInventoryLink(slabDetail.erp_link);
      setItemCode(slabDetail.item_code);
      // console.log(slabDetail, 'slabDetail')
      let finishes = [...product.finishes];
      let thickness = [...product.thickness];
      console.log(slabDetail.thickness)
      for (let i in slabDetail.thickness) {
        if (thicknessArray.indexOf(slabDetail.thickness[i].toLowerCase()) > -1) {
          thickness = [...thickness, slabDetail.thickness[i].toLowerCase()]
        }
      }
      for (let i in slabDetail.finishes) {
        if (finishesArray.indexOf(slabDetail.finishes[i]) > -1) {
          finishes = [...finishes, slabDetail.finishes[i]]
        }
      }


      dispatch(setProductDetails({...product, finishes, thickness}));
    }
  }, [slabDetail])

  useEffect(() => {
    if (product) {
      setStoneFnish(product.stone_finishes);
      setInventoryLink(product?.erp_link);
      setItemCode(product?.sku);
    }
  }, [product])


  const saveSteptwoHandler = (e) => {
    e.preventDefault();
    const stepTwoObj = {
      erp_link: erp_link,
      sku: item_code,

      // finishes: [
      //   {
      //     name: "Polished",
      //     thickness: "2.0 cm",
      //     types: [],
      //   },
      //   {
      //     name: "Matte",
      //     thickness: "0.0 cm",
      //     types: [],
      //   },
      //   {
      //     name: "Glossy",
      //     thickness: "3.0 cm",
      //     types: [],
      //   },
      // ],
    };

    dispatch(setProductDetails({...product, ...stepTwoObj}));

    // localStorage.setItem("stepTwo", JSON.stringify(stepTwoObj));
    nextStep();
    // const currentStep = 3;
  };

  const handleThickness = (e, name) => {
    if (e.target.checked) {
      dispatch(setProductDetails({...product, thickness: [...product.thickness, name]}))
    } else {
      if (product.thickness.indexOf(name) > -1) {
        const thickness = [...product.thickness];
        const index = product.thickness.indexOf(name)
        thickness.splice(index, 1);
        dispatch(setProductDetails({...product, thickness: [...thickness]}))
      }
    }
  }

  const handleFinishes = (e, name) => {
    if (e.target.checked) {
      dispatch(setProductDetails({...product, finishes: [...product.finishes, name]}))
    } else {
      if (product.finishes.indexOf(name) > -1) {
        const finishes = [...product.finishes];
        const index = product.finishes.indexOf(name)
        finishes.splice(index, 1);
        dispatch(setProductDetails({...product, finishes: [...finishes]}))
      }
    }
  }

  return (
    <div className="card_container">
      <div className="stone"> Product Details</div>

      <div className="steps_container">
        <div className="step step_1">
          <span>1</span>
          <p>Details</p>
        </div>
        <div className="line"></div>
        <div className="step step_2 active" >
          <span>2</span>
          <p>Info</p>
        </div>
        <div className="line"></div>
        <div className="step step_3">
          <span>3</span>
          <p>Media</p>
        </div>
      </div>
      <form>
        <div className="flex">
          <div className="left_side flex">
            <div className="finish_wrapper flex_column">
              <p>Finishes Available</p>
              {finishesArray.map((stnFnish) => (
                <>
                  <div className="checkbox_section">
                    <input type="checkbox" className="checkbox" checked={product.finishes.indexOf(stnFnish) > -1} onChange={(e) => handleFinishes(e, stnFnish)} />
                    <span className="checkmark"></span>
                    <label>{stnFnish}</label>
                  </div>
                </>
              ))}
            </div>

            <div className="thikness_wrapper flex_column">
              <p>Thicknesses Available</p>
              {thicknessArray.map((stnThickness) => (
                <>
                  <div className="checkbox_section">
                    <input type="checkbox" className="checkbox" checked={product.thickness.indexOf(stnThickness) > -1} onChange={(e) => handleThickness(e, stnThickness)} />
                    <span className="checkmark"></span>
                    <label>{stnThickness}</label>
                  </div>
                </>
              ))}

            </div>
          </div>

          <div className="right_side">
            {/* <div className="form_group"> */}
            <div className="form_controler name-section mt-2">
              <label>Live Inventory Link</label>
              <input
                type="text"
                placeholder="Enter Stone Name"
                value={erp_link}
                onChange={(e) => dispatch(setProductDetails({...product, erp_link: e.target.value}))}
              />
            </div>

            <div className="form_controler name-section">
              <label>Item Code</label>
              <input type="text" value={item_code} placeholder="Enter Stone Name" onChange={(e) => dispatch(setProductDetails({...product, sku: e.target.value}))} />
            </div>
          </div>
        </div>

        <div className="card_footer">
          <div className="btn_grp">
            <p onClick={(e) => {e.preventDefault(); preStep()}}><svg xmlns="http://www.w3.org/2000/svg" width="5.96" height="11.015" viewBox="0 0 5.96 10.015">
              <path id="arrow_dow_copy" data-name="arrow dow copy" d="M1171.727,5580.225a.82.82,0,0,1,.081,1.08l-.081.092-3.685,3.593,3.685,3.593a.82.82,0,0,1,.081,1.08l-.081.092a.849.849,0,0,1-1.094.081l-.094-.081-4.279-4.179a.82.82,0,0,1-.081-1.079l.081-.092,4.279-4.179A.849.849,0,0,1,1171.727,5580.225Z" transform="translate(-1166.013 -5579.982)" fill="#7a86a1" />
            </svg>
              &nbsp;&nbsp;Previous step</p>
          </div>
          <div className="btn_grp">
            <button className="primary btn" onClick={saveSteptwoHandler}>Next</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Info;
