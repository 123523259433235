import React, {useEffect, useState} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./dashboard.scss"; // Import the styles
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../redux/authActions";
import SlabDetails from "./SlabDetails";
import Modal from "./ModalComponent";
import Toast from "./toast";

const featureSettings = {
  dots: true,
  infinite: false,
  arrows: true,
  speed: 360,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: false,
  gap: "20px",
};

const Dashboard = () => {
  const [categories, setCategories] = useState([]);
  const [stones, setStones] = useState([]);
  const [originalStones, setOriginalStones] = useState([]);
  const [showLoader, setLoader] = useState(false)

  const [selectedCat, setSelectedCat] = useState(null)

  const [modalData, setModalData] = useState(false);
  const [stoneId, setStoneId] = useState(null);
  const [toastData, setToastData] = useState({});
  const [slab, setSlab] = useState({});
  const authToken = useSelector((state) => state.auth.authToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [search, setSearch] = useState('')

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("authToken"); // Clear token from localStorage
    navigate("/login");
    // You can also clear localStorage or any other necessary tasks here
  };

  const actionHandler = () => {
    alert("Its Work");
  };

  useEffect(() => {
    getAllSlabs();
  }, []);

  const getAllSlabs = (category) => {
    const url = category ? `?category_code=${category}` : '';
    setLoader(true)
    setSlab({});
    setStones([]);
    fetch("https://api.kivastone.com/inventory/stone/v1" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authToken,
      },
    })
      .then((res) => {
        if (res.status === 401) {
          dispatch(logout());
          localStorage.removeItem("authToken"); // Clear token from localStorage
          navigate("/login");
          throw new Error;
          // You can also clear localStorage or any other necessary tasks here
        };
        return res.json()
      })
      .then((result) => {
        // store the posts in the reactive state
        const cateogry = [...result.category_stats];
        const stones = [...result.stones];
        setCategories(cateogry);
        setStones(stones);
        setOriginalStones(stones);

        setLoader(false)
      })
      .catch((err) => {
        console.log(err);
        setLoader(false)

      });
  };

  const getSlabDetails = (categoryCode, stoneName) => {
    fetch(
      `https://api.kivastone.com/inventory/stone/v1?category_code=${categoryCode}&stone_name=${stoneName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authToken,
        },
      }
    )
      .then((res) => {
        if (res.status === 401) {
          dispatch(logout());
          localStorage.removeItem("authToken"); // Clear token from localStorage
          navigate("/login");
          throw new Error;
          // You can also clear localStorage or any other necessary tasks here
        };
        return res.json()
      })
      .then((result) => {
        const slabData = [...result.stones];
        setSlab(slabData[0]);
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const tempStones = originalStones.filter(stone => {
      return stone.title?.toLowerCase().includes(search.toLowerCase())
    })
    setStones(tempStones);
    return false;
  }

  const deleteSlab = (stoneId) => {
    setToastData({
      isOpen: true, type: 'red',
      title: "XYZ listing has been deleted from the database",
      onClose: () => {setToastData({isOpen: false})}
    });
    fetch(
      `https://api.kivastone.com/inventory/stone/v1/delete/${stoneId}/`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authToken,
        },
      }
    )
      .then((res) => {
        if (res.status === 401) {
          dispatch(logout());
          localStorage.removeItem("authToken"); // Clear token from localStorage
          navigate("/login");
          throw new Error;
          // You can also clear localStorage or any other necessary tasks here
        };
        return res.json()
      })
      .then((result) => {
        getAllSlabs();
        setSlab({})
        setModalData({
          isOpen: false
        })
      }).finally(() => {
        setTimeout(() => {
          setToastData({isOpen: false});
        }, 3000)
      })
  };

  const markFeatured = (e, stoneId) => {
    e.stopPropagation();
    fetch(
      `https://api.kivastone.com/inventory/stone/v1/feature/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authToken,
        },
        body: JSON.stringify({"stone_id": stoneId})
      }
    )
      .then((res) => {
        if (res.status === 401) {
          dispatch(logout());
          localStorage.removeItem("authToken"); // Clear token from localStorage
          navigate("/login");
          throw new Error;
          // You can also clear localStorage or any other necessary tasks here
        };
        return res.json()
      })
      .then((result) => {
        setModalData({
          isOpen: false,
        })
        getAllSlabs();
      });
  };

  const handleCategory = (e, category) => {
    if (category === selectedCat) {
      getAllSlabs()
      setSelectedCat(null)
    } else {
      setSelectedCat(category);
      getAllSlabs(category)
    }
  }

  return (
    <div className="dashboard-wrapper">
      <Modal {...modalData} />
      <Toast {...toastData} />
      <nav className="navbar">
        <div className="navbar-brand">
          <Link to='/'>
            <img src="../logo-black.png" width={80} />
          </Link>
        </div>
        <ul className="navbar-nav">
          <li className="nav-item">
            <div onClick={handleLogout}>Logout</div>
          </li>
        </ul>
      </nav>
      <div className="dashboard-content">
        <div className="flex-container">
          <div className="left-section">
            <div className="dashboard-card">
              <div className="greeting">
                Good Morning{" "}
                <Link to={"/product/add"}>
                  <button className="list-product-button">List Product</button>
                </Link>
              </div>

              <div className="details">
                <div className="label">
                  <span>175</span> products live
                </div>
                <div className="label">
                  <span>500</span> new listings
                </div>
              </div>
            </div>
            <SlabDetails slabData={slab} />

            <div className="feature-title">Featured Collection</div>
            <div className="feature-dashboard-card">
              <div className="vertical-item-list">

                {originalStones.map((stone) => {
                  if (stone.is_featured)
                    return (
                      <div className="vertical-item">
                        <div className="vertical-item-image">
                          <img src={stone.slab_images[0]} />
                        </div>
                        <div className="vertical-item-info">
                          <div className="vertical-item-name">{stone.title}</div>
                          <div className="vertical-item-description">
                            Item code {stone.item_code}
                            {/* <div className="vertical-item-date">Date - {stone.create_at}</div> */}
                          </div>
                        </div>
                      </div>
                    )
                })
                }
                {/* Add more items here */}
              </div>
            </div>
          </div>
          <div className="right-section">
            <div className="feature-gallery">
              <Slider {...featureSettings}>
                {categories?.map((category) => {
                  return (
                    <div className={`feature-card ${selectedCat === category.category_code && 'active'}`} onClick={(e) => handleCategory(e, category.category_code)}>
                      <div
                        className={`feature-card-content ${category.category_code?.split(" ")[0]
                          }`}
                      >
                        <div className="category-detail">
                          {category.category_code}
                          <div className="count">
                            {category.stone_count} Items
                          </div>
                        </div>
                        {category.category_code?.split(" ")[0] ===
                          "PORCELAIN" && (
                            <img
                              className="category-image"
                              src="../../levante.png"
                              width={80}
                            />
                          )}
                        {category.category_code?.split(" ")[0] === "QUARTZ" && (
                          <img
                            className="category-image"
                            src="../../imperia.png"
                            width={80}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}

                {/* Add more feature cards */}
              </Slider>
              {/* Add more feature cards */}
            </div>
            <form>
              <div className="search-container">

                <input placeholder="Search for any slab" onChange={(e) => setSearch(e.target.value)} />
                <button type="submit" className="search-btn" onClick={handleSearch}>Go</button>

              </div>
            </form>
            <div className="listings">
              <div className="listing-title">Most Recent Listings {selectedCat && <><span>- </span>{selectedCat}</>}</div>
              {
                showLoader && <div className="loader"><img src="../../kiva-white-dark.gif" width={100} /></div>
              }
              {stones.map((stone) => (
                <div
                  className="listing-item"
                  onClick={() =>
                    getSlabDetails(stone.category_code, stone.title)
                  }
                >

                  <div className="listing-intro">
                    <img
                      src={stone.slab_images[0]}
                      style={{width: 60, height: 60}}
                    />
                    <div className="listing-name">
                      {stone.title}
                      <div className="tags">
                        <span className="listing-desc">
                          {stone.finishes.map((ele, index) => (
                            <>
                              {ele}
                              {index !== stone.finishes.length - 1 &&
                                ", "}
                            </>
                          ))}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="listing-size">{stone.thickness.map((ele, index) => (
                    <>
                      {ele.toLowerCase()}
                      {index !== stone.thickness.length - 1 &&
                        ", "}
                    </>
                  ))}</div>
                  <div className="listing-action">
                    <div class="dropdown">
                      <img src="../../icon.png" />
                      <div class="dropdown-content">
                        <div class="dropdown-title"> ACTIONS </div>

                        <Link
                          to={`/product/${stone.category_code}/${stone.title}`}
                        ><div className="dropdown-list">
                            Edit
                          </div>
                        </Link>

                        {
                          !stone.is_featured && <div className="dropdown-list" onClick={(e) => {
                            setModalData({
                              isOpen: true,
                              title: 'Caution!',
                              description: 'This action cannot be undone!',
                              subDescription: 'Are you sure you wish to make this listing a featured stone?',
                              actionBtn: 'Make Featured',
                              onAction: (e) => {e.stopPropagation(); markFeatured(e, stone.id)},
                              onClose: () => setModalData({isOpen: false}),
                              type: 'red'
                            })
                          }}
                          >Make Featured</div>
                        }

                        <div
                          className="dropdown-list"
                          onClick={(e) => {
                            setModalData({
                              isOpen: true,
                              title: 'Caution!',
                              description: 'This action cannot be undone!',
                              subDescription: 'Are you sure you wish to delete this listing?',
                              actionBtn: 'Delete',
                              onAction: () => deleteSlab(stone.id),
                              onClose: () => setModalData({isOpen: false}),
                              type: 'red'
                            })
                          }}
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
