import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoginPage from "./components/LoginPage";
import Dashboard from "./components/Dashboard";
import { setAuthToken } from "./redux/authActions";
import ProductDetail from "./components/ProductDetail";

const App = () => {
  const authToken = useSelector((state) => state.auth.authToken);
  const dispatch = useDispatch();

  useEffect(() => {
    const savedToken = localStorage.getItem("authToken");
    if (savedToken) {
      dispatch(setAuthToken(savedToken));
    }
  }, [dispatch]);

  const PrivateRoute = ({ element }) => {
    return authToken ? element : <Navigate to="/login" />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            authToken ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/dashboard"
          element={<PrivateRoute element={<Dashboard />} />}
        />
        <Route
          path="/product/:code/:name"
          element={<PrivateRoute element={<ProductDetail />} />}
        />
        <Route
          path="/product/add"
          element={<PrivateRoute element={<ProductDetail />} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
