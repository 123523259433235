import React, {useState} from "react";
import _ from "lodash";
import {Link} from "react-router-dom";

const SlabDetails = ({slabData}) => {
  const [isCollapsed, setCollapse] = useState(false);
  return (
    <>
      {!_.isEmpty(slabData) && (
        <div className="slab-card">
          {
            !isCollapsed &&
            <>
              <div className="greeting">
                {slabData.title}
                <Link to={`/product/${slabData.category_code}/${slabData.title}`}>
                  <button className="list-product-button">Edit Slab Info</button>
                </Link>
              </div>
              <div className="slab_details">
                <div>
                  <img className="slab_image" src={slabData.slab_images[0]} />
                </div>

                <div className="slab-details-type">

                  <p>Thicknessess</p>
                  <div className="thickness-col">
                    {
                      slabData.thickness.map((ele) => ele)
                    }
                  </div>

                  <div className="finishes">
                    <p>Finishes Available</p>
                    <div className="thickness-col">
                      {
                        slabData.finishes.map((ele) => <span>{ele}</span>)
                      }
                    </div>
                  </div>
                </div>
              </div>


            </>
          }
          <div className="collapse" onClick={() => setCollapse(!isCollapsed)}>
            <img src="../../collapse2.png" className={`${isCollapsed && 'collapsedTrue'}`} />
          </div>
        </div>
      )}
    </>
  );
};

export default SlabDetails;
