import React, {useState, useRef, useEffect} from 'react';
import './drag.scss';

function ImageUploader({name, stoneImage, base64Image, multiple}) {
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleManualUpload = (e) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    console.log(base64Image, 'base64Image');
  }, [stoneImage, base64Image.length])

  const handleImageDrop = async (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const images = [];

    for (let i = 0; i < files.length; i++) {
      try {
        const base64 = await processImage(files[i]);
        if (multiple) {
          throw new Error('Limit of 4 exceeded!')
        }
        images.push(base64);
      } catch (err) {
        setError(err.message);
      }
    }

    stoneImage(images);
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    let images = [];

    for (let i = 0; i < files.length; i++) {
      try {
        const base64 = await processImage(files[i]);
        if (multiple && files.length > 4) {
          throw new Error('Limit of 4 exceeded!')
        } else {
          setError(null)
        }
        if (!multiple) {
          clearImage(e)
          images.push(base64);
        } else {
          images.push(base64);
        }
      } catch (err) {
        setError(err.message);
      }
    }
    console.log(images, 'visu')
    stoneImage(images);
  };

  const processImage = (file) => {
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const base64 = e.target.result;
          resolve(base64);
        };

        reader.onerror = () => {
          reject(new Error('Failed to read one or more images.'));
        };

        reader.readAsDataURL(file);
      } else {
        reject(new Error('No file selected.'));
      }
    });
  };

  const clearImage = (e) => {
    e.stopPropagation();
    stoneImage([]);
    fileInputRef.current.value = null
  };

  console.log(base64Image, 'base64Image')

  return (
    <div className="image-uploader">
      <div className="stone"> {name}</div>
      <div className="drop-area" onDrop={handleImageDrop} onDragOver={handleDragOver}>
        {base64Image?.length ? (
          <>
            <div className="cross" onClick={clearImage}>
              <svg xmlns="http://www.w3.org/2000/svg" width="7.099" height="8.282" viewBox="0 0 7.099 8.282">
                <path id="icon_delete" data-name="icon delete" d="M1182.027,3506.264a1.177,1.177,0,0,1-1.156-1.17c-.124-1.179-.33-3.966-.334-3.994a.336.336,0,0,1,.075-.231.271.271,0,0,1,.206-.1h5.47a.277.277,0,0,1,.206.1.318.318,0,0,1,.072.231c0,.028-.21,2.82-.33,3.994a1.177,1.177,0,0,1-1.183,1.17c-.509.011-1.008.015-1.5.015C1183.032,3506.281,1182.522,3506.276,1182.027,3506.264Zm-1.742-6.157a.3.3,0,0,1-.281-.3v-.157a.293.293,0,0,1,.281-.3h1.15a.513.513,0,0,0,.49-.42l.06-.282a.8.8,0,0,1,.754-.641h1.628a.793.793,0,0,1,.749.622l.064.3a.511.511,0,0,0,.49.421h1.15a.293.293,0,0,1,.282.3h0v.157a.3.3,0,0,1-.281.3Z" transform="translate(-1180.003 -3497.999)" fill="#7a86a1" />
              </svg>
              &nbsp;&nbsp;Delete
            </div>
            <div className="preview">
              {base64Image.map((imgSrc, index) => (
                <img
                  key={index}
                  src={`${imgSrc.includes('http') ? imgSrc : 'data:image/png;base64, ' + imgSrc}`}
                  alt="Uploaded"
                  className="uploaded-image"
                />
              ))}
            </div>
          </>
        ) : (
          'Drag and drop your product image file here'
        )}
        <span className="or">or</span>
        <input type="file" ref={fileInputRef} style={{display: 'none'}} onChange={handleFileChange} multiple={multiple} />
        <button onClick={handleManualUpload} className="upload-button">
          <svg
            className="uploadIcon"
            xmlns="http://www.w3.org/2000/svg"
            width="12.003"
            height="13.007"
            viewBox="0 0 12.003 13.007"
          >
            <path
              id="icon_upload"
              data-name="icon upload"
              d="M1042.676,2751a2.749,2.749,0,0,1-2.671-2.7v-3.241a2.76,2.76,0,0,1,2.539-2.816h3v3.818a.473.473,0,0,0,.448.495.468.468,0,0,0,.472-.428l0-.067v-3.818h2.869a2.749,2.749,0,0,1,2.671,2.7v3.235a2.762,2.762,0,0,1-2.539,2.825h-.125Zm2.869-8.763v-2.591l-.955,1a.451.451,0,0,1-.654,0,.493.493,0,0,1-.051-.626l.045-.056,1.746-1.828a.447.447,0,0,1,.33-.144.44.44,0,0,1,.27.094l.054.051,1.747,1.828a.5.5,0,0,1-.011.682.448.448,0,0,1-.589.047l-.051-.047-.954-1v2.591Z"
              transform="translate(-1040.006 -2737.991)"
              fill="#7a86a1"
            />
          </svg>
          Browse files
        </button>
        {error && <p className="error-message">{error}</p>}
      </div>

    </div>
  );
}

export default ImageUploader;
