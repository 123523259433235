import React, {useEffect, useState} from "react";
import Details from "./Details";
import Info from "./Info";
import Media from "./Media";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {logout} from "../../../redux/authActions";

const Step = ({getSlab}) => {
  const [slab, setSlab] = useState();
  const [currentStep, setCurrentStep] = useState(1);

  const queryParameters = new useParams();
  const categoryCode = queryParameters.code;
  const stoneName = queryParameters.name;
  console.log(queryParameters);

  const authToken = useSelector((state) => state.auth.authToken);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('asas')
    getSlabDetails(categoryCode, stoneName);
  }, []);

  const navigate = useNavigate();
  const getSlabDetails = async (categoryCode, stoneName) => {
    await fetch(
      `https://api.kivastone.com/inventory/stone/v1?category_code=${categoryCode}&stone_name=${stoneName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authToken,
        },
      }
    )
      .then((res) => {
        if (res.status === 401) {
          dispatch(logout());
          localStorage.removeItem("authToken"); // Clear token from localStorage
          navigate("/login");
          throw new Error;
          // You can also clear localStorage or any other necessary tasks here
        };
        return res.json()
      })
      .then((result) => {
        const slabData = [...result.stones];
        console.log('1234')
        getSlab(slabData[0])
        setSlab(slabData[0]);


      });
  };

  const nextStep = () => {
    let a = currentStep + 1;
    setCurrentStep(a);
  };

  const prevStep = () => {
    let b = currentStep - 1;
    setCurrentStep(b);
  };


  console.log(slab, 'slabData')
  switch (currentStep) {
    case 1:
      return (
        <div className="flex-item">

          <Details slabDetail={slab} nextStep={nextStep} preStep={prevStep} />

        </div>
      );
      break;

    case 2:
      return (
        <div className="flex-item">
          {<Info slabDetail={slab} nextStep={nextStep} preStep={prevStep} />}
        </div>
      );
      break;

    case 3:
      return (
        <div className="flex-item">
          {<Media slabDetail={slab} preStep={prevStep} />}
        </div>
      );
    default:
  }
};

export default Step;
